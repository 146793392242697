<template>
  <div>
    <v-data-table :headers="headers" :items="finalResults" :items-per-page="15" class="elevation-1">
      <template v-slot:[`item.stageName`]="{ item }">
        <v-row align="center">
          <v-col class="grow">
            <v-chip   class="me-2" label>{{ item.attemptNumber }}</v-chip>
            <v-chip   class="me-2" label v-if="item.courseType">{{ item.courseType }}</v-chip>
            <v-chip   class="me-2" label>{{
              (item.stageName.includes("المرحلة") ? "" : "المرحلة ") + item.stageName
            }}</v-chip>
            <v-chip outlined class="me-2" label :title="item.publishDateFormat">{{ item.publishDateText }}</v-chip>
   
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="font-weight: bold;" outlined color="primary" :loading="degreesLoading" dark @click="getFinalDegree(item.guid)">
            <v-icon class="me-2" v-if="!item.openByStudent" color="secondary">mdi-email</v-icon>
            <v-icon class="me-2" v-if="item.openByStudent" >mdi-email-open</v-icon>
            {{ $t("home.degreeDownload") }}

          </v-btn>
        </v-card-actions>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      finalResults: [],
      overlay: false,
      degreesLoading: false,
      headers: [
        { text: this.$t("finalResults.stageName"), value: "stageName" },
        { text: "", value: "action" }
      ]
    };
  },
  methods: {
    getFinalDegree(guid) {
      this.degreesLoading = true;
      this.axios({
        url: `Degrees/GetStudentFinalResult?guid=${guid}`,
        method: "GET",
        responseType: "blob"
      })
        .then(res => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "finalDegrees";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch()
        .finally(() => {
          this.degreesLoading = false;
        });
    },
    getfinalResults() {
      this.overlay = true;
      this.axios
        .get("Degrees/GetStudentAllFinalResults")
        .then(res => {
          this.finalResults = res.data.data;

          this.finalResults.forEach(i => {
            i.publishDateFormat = this.$moment(i.publishDate).format("YYYY/MM/DD - h:mm A ");
            i.publishDateText = this.$moment(i.publishDate).fromNow();
          });

          console.log(this.finalResults);
        })
        .catch(err => {})
        .finally(() => {
          this.overlay = false;
        });
    }
  },
  created() {
    this.getfinalResults();
  }
};
</script>

<style></style>
